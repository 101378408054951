import React, {useState} from 'react';

export const Level = ({level, setLevel, reset}) => {
    const [showModal, setShowModal] = useState(0);

    const renderButton = (label, change, active) => (
        <button
            className={`spaceTop ${active ? '' : 'disabled'}`}
            onClick={() => {
                if (!active) return
                // setLevel(ov => change + ov)
                setLevel(ov => change + parseInt(ov))
            }}
        >
            {label}
        </button>
    )

    const renderModal = () => (
        <div className="levelModalWrapper">
            <div
                className="levelModalBackdrop"
                onClick={() => setShowModal(0)}
            />
            {/*<div className="spacer"/>*/}
            <div className="levelModal">
                Level
                {renderButton('+', 1, level < 20 )}
                <span className="spaceTop">{level}</span>
                {renderButton('-', -1, level > 1)}
                ______
                <button
                    className={'wide spaceTop'}
                    onClick={() => {
                        reset()
                        setShowModal(0)
                    }}
                >
                    Rest
                </button>
            </div>
            <div className="spacer"/>
            <div className="spacer"/>
        </div>
    );


    return (
        <>
        <span onClick={() => setShowModal(1)}>Sorcerer lvl {level}</span>
        {showModal ? renderModal() : <></>}
        </>
    );
}