import React, {useEffect, useState} from 'react';
import './SlotsAndPoints.css'
import {Level} from "./Level";
import {Slots} from "./Slots";
import {Points} from "./Points";

const getStored = (key, defaultValue) => {
    const stored = localStorage.getItem(key);
    if (!stored) return defaultValue;
    return JSON.parse(stored);
}

export const SlotsAndPoints = () => {
    const [level, setLevel] = useState(localStorage.getItem('slotsLevel') ?? 1);
    const [usedSlots, setUsedSlots] = useState(getStored('usedSlots', []))
    const [usedPoints, setUsedPoints] = useState(getStored('usedPoints', 0))

    useEffect(() => localStorage.setItem('slotsLevel', level), [level])

    const reset = () => {
        setUsedSlots([])
        setUsedPoints(0)
    }

    return (
        <div className="slotsAndPoints page noSelect">
            <h2><Level
                level={level}
                setLevel={setLevel}
                reset={reset}
            /></h2>
            <div className="spacer"/>
            <Slots
                level={level}
                usedSlots={usedSlots}
                setUsedSlots={setUsedSlots}
            />
            <Points
                key={`pointsForLvl${level}`}
                level={level}
                usedPoints={usedPoints}
                setUsedPoints={setUsedPoints}
            />
            <div className="spacer"/>
            <div className="spacer"/>
            <div className="spacer"/>
        </div>
    );
}