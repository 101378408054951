import React, {useEffect, useState} from 'react';
import './App.css';
import {Spells} from "./Pages/Spells/Spells";
import {Search} from "./Pages/Search/Search";
import {PrivacyPolicy} from "./Pages/PrivacyPolicy";
import {WildMagic} from "./Pages/WildMagic/WildMagic";
import useEffectPartialDeps from "./utils/UseEffectPartialDeps";
import {SlotsAndPoints} from "./Pages/SlotsAndPoints/SlotsAndPoints";


const getMyLocalSpells = () => {
    let s = localStorage.getItem('mySpells')
    return s ? JSON.parse(s) : [];
}

let initScrollDone, spellBookRef = React.createRef();

function App() {
    const [mySpells, setMySpells] = useState(getMyLocalSpells());
    const [width, setWidth] = useState();
    const [showWildMagic, setShowWildMagic] = useState(localStorage.getItem('showWildMagic') === 'true');
    const [showSlots, setShowSlots] = useState(localStorage.getItem('showSlots') === 'true');

    useEffect(() => localStorage.setItem('mySpells', JSON.stringify(mySpells)), [mySpells])

    useEffect(() => {
        const extraPagesCount = [showSlots, showWildMagic].filter(t => !!t).length;
        setWidth(() => window.innerWidth * (2 + extraPagesCount));
        localStorage.setItem('showWildMagic', JSON.stringify(showWildMagic));
        localStorage.setItem('showSlots', JSON.stringify(showSlots));
    }, [showWildMagic, showSlots])

    useEffectPartialDeps(() => {
        if (mySpells && mySpells.length === 0) {
            initScrollDone = true;
            return;
        }
        if (initScrollDone || !spellBookRef.current) return;
        spellBookRef.current.scrollIntoView({behavior: "smooth"});
        initScrollDone = true;
    }, [width, mySpells])

    if (window.location.pathname === "/privacy")
        return (
            <div className={'App'}>
                <PrivacyPolicy/>
            </div>
        )
    if (!width) return <></>

    return (
        <div className={'App'}>
            <div
                className="contentWrapper"
                style={{width}}
            >
                <Search
                    mySpells={mySpells}
                    setMySpells={setMySpells}
                    flipWildMagic={() => setShowWildMagic(wm => !wm)}
                    flipShowSlots={() => setShowSlots(wm => !wm)}
                />
                <Spells
                    mySpells={mySpells}
                    setMySpells={setMySpells}
                    spellBookRef={spellBookRef}
                />
                {showSlots && (<SlotsAndPoints/>)}
                {showWildMagic && (<WildMagic/>)}
            </div>
        </div>
    );
}

export default App;
