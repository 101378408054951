import React from 'react';
import {IncrementDecrementor} from "./IncrementDecrementor";
import {Orb} from "./Orb";

export const Points = ({level, usedPoints, setUsedPoints}) => {
    const totalPoints = level === 1 ? 0 : level;

    const updatePoints = (change) => {
        const newUsed = parseInt(usedPoints) + change;
        if(newUsed < 0 || newUsed > totalPoints) {
            console.log('returning early');
            return;
        }
        setUsedPoints(newUsed);
    }

    const renderOrbs = () => {
        const pointOrbs = [];
        for (let i = 0; i < totalPoints; i++) {
            pointOrbs.push(
                <Orb key={`orb${i}`} spent={usedPoints > i} level={level} point/>
            )
        }
        pointOrbs.reverse()
        return pointOrbs;
    }

    return (
        <>
            <h3>Points</h3>
        <IncrementDecrementor
            className="sorcPoints"
            onChange={updatePoints}
        >
            <div className="sorcPointOrbs">
                {renderOrbs()}
            </div>
        </IncrementDecrementor>
        </>
    );
}