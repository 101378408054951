import React, {useEffect, useState} from 'react';
import './Search.css'
import {Picker} from "../../Picker/Picker";
import {Spell} from "../../Components/Spell";
import {addDividers, sortSpells, stringMatch} from "../../utils/utils";
import { ReactComponent as Plus } from "../../resources/plus.svg";
import { ReactComponent as Minus } from "../../resources/minus.svg";
import { ReactComponent as Cross } from "../../resources/cross.svg";
import {spells} from '../../Spells'
import useEffectOnce from "../../utils/UseEffectOnce";

const getLocal = (key, string) => {
    let s = localStorage.getItem(key);
    return s ? string ? s : JSON.parse(s) : null;
}

let code = [],
    wildKey = [1, 3, 3, 7],
    slotsKey = [8, 8, 8, 8];

export const Search = ({mySpells, setMySpells, flipWildMagic, flipShowSlots}) => {
    const [searchFocus, setSearchFocus] = useState(false)
    const [search, setSearch] = useState('')
    const [levels, setLevels] = useState(getLocal('levels') || []);
    const [playerClass, setPlayerClass] = useState(getLocal('class', true));

    const [adding, setAdding] = useState({});

    useEffectOnce(() => {
        localStorage.removeItem('spells');
    })

    const onLevelClick = (l) => {
        if (code.length === 4) code.shift();
        code.push(l);
        if (JSON.stringify(wildKey) === JSON.stringify(code)) flipWildMagic()
        if (JSON.stringify(slotsKey) === JSON.stringify(code)) flipShowSlots()
    }

    const save = (key, value, stringify) =>
        localStorage
            .setItem(
                key,
                stringify ? JSON.stringify(value) : value || ''
            )


    useEffect(() => save('class', playerClass), [playerClass])
    useEffect(() => save('levels', levels, true), [levels])

    if (!spells) return <></>;

    const renderResults = () => {
        if (!spells) return <><p>This takes time</p><p>Once</p></>
        let filtered = getFilteredResults();
        if (filtered.length === 0) return <p>No match</p>;
        return addDividers(
            filtered.sort(sortSpells)
        ).map(renderResult);
    }


    const mySpellIndexes = mySpells.map(s => s.index);
    const renderResult = (s) => (
        <div key={s.index} className="spellResultWrapper">
        <Spell
            spell={s}
            addButton={renderAddButton(s)}
            removeButton={renderRemoveButton(s)}
            added={mySpellIndexes.includes(s.index)}
            adding={adding[s.index]}
        />
        </div>
    )

    const renderRemoveButton = (s) => {
        return (
            <button
                className={`addOrRemoveSpellButton removeButton`}
                onClick={() => {
                    let myNewSpells = [...mySpells];
                    myNewSpells.splice(myNewSpells.findIndex(ix => ix.index === s.index), 1);
                    setMySpells(myNewSpells);
                }}
            >
                <Minus/>
            </button>
        )
    }

    const renderAddButton = (s) => {
        return (
            <button
                className={`addOrRemoveSpellButton addButton`}
                onClick={() => {
                    setMySpells([...mySpells, s])
                    setAdding(a => ({...a, [s.index]: {gone: false} }));
                    setTimeout(() => setAdding(a => ({...a, [s.index]: {gone: true} })))
                    setTimeout(() => setAdding(a => ({...a, [s.index]: undefined})), 500)
                }}
            >
                <Plus/>
            </button>
        )
    }


    const getFilteredResults = () => {
        let filtered = [...spells];

        if (levels.length > 0)
            filtered = filtered.filter(s => levels.includes(s.level))

        if (playerClass)
            filtered = filtered.filter(s => s.classes.some(c => c.index === playerClass))

        if (search)
            filtered = filtered.filter(s => stringMatch(s.name, search))

        return filtered;
    }

    return (
        <div className="search page">
            <div className="filters">
                <h2>Archive</h2>
                <div className="searchBox">
                    <input
                        autoComplete={"off"}
                        id="nameSearch"
                        className="input"
                        type="search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => setSearchFocus(false)}
                    />
                    <button
                        className="clearSearchBoxButton"
                        onClick={() => setSearch('')}
                    >
                        <Cross/>
                    </button>
                </div>
                <div className={`levelAndClass ${searchFocus ? 'hidden' : ''}`}>
                    <Picker
                        selectedLevels={levels}
                        setSelectedLevels={setLevels}
                        selectedClass={playerClass}
                        setSelectedClass={setPlayerClass}
                        onLevelClick={onLevelClick}
                    />
                </div>
                <div className="filterFade">
                    <p>
                        Spells:
                    </p>
                </div>
            </div>
            <div className="results">
                {renderResults()}
            </div>
        </div>
    );
}