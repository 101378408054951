import React from 'react';

export const PrivacyPolicy = () => (
    <div
        className="privacyPolicy page"
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <h2>Privacy policy</h2>
        <p>This app does not collect any data about you.</p>
        <p>You are alone in the universe.</p>
        <p>Your spell selection and search parameters are stored locally.</p>
    </div>
);