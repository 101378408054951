export const levelSlots = [
    [],
    [2],
    [3],
    [4, 2],
    [4, 3],
    [4, 3, 2],
    [4, 3, 3],
    [4, 3, 3, 1],
    [4, 3, 3, 2],
    [ 4, 3, 3, 3, 1],
    [ 4, 3, 3, 3, 2],
    [ 4, 3, 3, 3, 2, 1],
    [ 4, 3, 3, 3, 2, 1],
    [ 4, 3, 3, 3, 2, 1, 1],
    [ 4, 3, 3, 3, 2, 1, 1],
    [ 4, 3, 3, 3, 2, 1, 1, 1],
    [ 4, 3, 3, 3, 2, 1, 1, 1],
    [ 4, 3, 3, 3, 2, 1, 1, 1, 1],
    [ 4, 3, 3, 3, 3, 1, 1, 1, 1],
    [ 4, 3, 3, 3, 3, 2, 1, 1, 1],
    [ 4, 3, 3, 3, 3, 2, 2, 1, 1],
]
