import React from 'react';
import {Spell} from "../../Components/Spell";
import './Spells.css'
import { ReactComponent as Skull } from "../../resources/skull.svg";

import {addDividers, sortSpells} from "../../utils/utils";

export const Spells = ({mySpells, spellBookRef}) => (
    <div
        className="spells page"
        ref={spellBookRef}
    >
        <div className="spellsHeader">
            <h2>Spell book</h2>
        </div>
        <div className="spellsContent">
            {
                mySpells && mySpells.length > 0 ?
                addDividers(mySpells.sort(sortSpells)).map(s => (
                <Spell key={s.index} spell={s}/>
            ))
                    : <Skull/>

            }
        </div>
    </div>
);
