import React from 'react';

export const Orb = ({spent, point, level}) => {

    const center = {
        cx:"50",
        cy:"50"
    }

    const colors = spent ?
        [
            '#101010',
            '#1e1e1e',
            '#2d2d2d',
            '#333333',
        ] :point ?
        [
            '#005356',
            '#008c91',
            '#00c6ce',
            '#89f7ff',
        ]:
        [
            '#00386b',
            '#00549d',
            '#0073d5',
            '#4ba5ff',
        ]

    const size = 50 - level

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="orb"
            height={size ?? "50"}
            width={size ?? "50"}
            viewBox="0 0 100 100"
        >
            <g
                transform={ spent ? "rotate(180 50 50 )" : ''}
            >
            <circle
                {...center}
                r="50"
            />
             <circle
                {...center}
                r="43"
                fill={colors[1]}
            />
             <circle
                {...center}
                r="40"
                fill={colors[0]}
            />



            <circle
                cx="50"
                cy="45"
                r="33"
                fill={colors[1]}
            />

            <circle
                cx="55"
                cy="40"
                r="25"
                fill={colors[2]}
            />
            <circle
                cx="55"
                cy="33"
                r="10"
                fill={colors[3]}
            />
            </g>
        </svg>
    );
}