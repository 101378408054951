import React from 'react';

export const IncrementDecrementor = ({className, onChange, children}) => (
    <div
        className={className}
        onContextMenu={() => onChange(-1)}
        onClick={() => onChange(1)}
    >
        {children}
    </div>
);